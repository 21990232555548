<template>
  <v-container class="pa-0">
    <v-loading v-model="loading">
      <portal to="app-bar">
        <v-app-bar short app dark color="primary" elevation="0">
          <v-app-bar-nav-icon @click="$root.$emit('drawer')"/>
          <v-spacer/>
          <v-list-item>
            <v-list-item-content class="text-center">
              <v-list-item-title>Atendimento</v-list-item-title>
              <v-list-item-subtitle>
                {{ student.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-spacer/>
          <Avatar :initials="student.initials" :avatar="student.avatar.path"/>
        </v-app-bar>
      </portal>

      <portal to="app-footer">
        <v-footer app inset class="tertiary px-2">
          <v-btn outlined color="primary" @click="onClose">Fechar</v-btn>
          <v-spacer/>
          <v-btn depressed color="primary" :disabled="saving" :loading="saving" @click="confirmDialogSave = true">
            Finalizar
          </v-btn>
        </v-footer>
      </portal>
      
      <template v-for="item in attendance.programs">
        <v-list-item :key="item.id" @click="item.open = true">
          <v-list-item-content>
            <apply-program-dialog :program="item" @change="onChange"/>
            <v-list-item-title class="text-uppercase font-weight-bold">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.faseName }}, {{ item.helpName }} {{ showSeconds(item.helpName) ? `, ${item.delay}"` : '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <template v-if="isCompleted(item.applications)">
            <v-list-item-action>
              <v-icon dense color="green">mdi-check-bold</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
      </template>
    </v-loading>

    <ConfirmDialog cancel="Descartar" confirm="Pausar" v-model="confirmDialog" @confirm="onConfirm" @cancel="onCancel">
      O que você gostaria de fazer com o atendimento?
    </ConfirmDialog>

    <ConfirmDialog cancel="Não" confirm="Sim" v-model="confirmDialogSave" @confirm="onFinish" @cancel="confirmDialogSave = false">
      Deseja realmente finalizar o atendimento?
    </ConfirmDialog>
  </v-container>
</template>

<script>
  import ConfirmDialog from '../dialogs/ConfirmDialog'
  import ApplyProgramDialog from '../dialogs/ApplyProgramDialog'
  import AttendanceService from '../services/attendance'
  import StoreAttendanceModel from '../models/StoreAttendance'
  import StoreProgramModel from '../models/StoreProgram'
  import Avatar from '../components/Avatar'

  export default {
    components: {
      Avatar,
      ConfirmDialog,
      ApplyProgramDialog,
    },
    data: () => ({
      loading: false,
      saving: false,
      confirmDialog: false,
      confirmDialogSave: false,
      applyProgramDialog: false,
      program: StoreProgramModel,
      attendance: StoreAttendanceModel,
      student: {
        avatar: {name: null, path: null},
        id: null,
        initials: null,
        name: null,
      }
    }),
    mounted() {
      this.fetchStoreAttendance();
    },
    methods: {
      showSeconds(help) {
        return !['Ajuda necessária', 'Independente'].some(item => item.trim() === String(help).trim())
      },
      onClose() {
        this.confirmDialog = true
      },
      onConfirm() {
        this.gotoStudent();
      },
      onCancel() {
        this.onStoreDiscart()
          .then(() => {
            this.gotoStudent()
          })
      },
      onApply(program) {
        this.program = program;
        this.applyProgramDialog = true;
      },
      onChange(program) {
        this.onStoreUpdate(program).then(() => {})
      },
      isCompleted(applications) {
        return applications.every(item => item.status !== null)
      },
      fetchStoreAttendance() {
        this.loading = true

        AttendanceService.rescue(this.$route.query.id)
          .then((attendance) => {
            this.attendance = attendance;
            this.student = attendance.student;
            this.attendance.programs = this.attendance.programs.map(item => ({
              ...item,
              open: false,
            }));
            this.loading = false
          })
      },
      onStoreUpdate(program) {
        this.attendance.programs = this.attendance.programs.map(item => {
          return item.id == program.id ? program : item;
        });
        return AttendanceService.storeUpdate(this.attendance)
      },
      onStoreDiscart() {
        return AttendanceService.storeDiscart(this.attendance)
      },
      onFinish() {
        this.saving = true

        AttendanceService.create(this.attendance)
          .then((data) => {
            AttendanceService.storeDiscart(this.attendance)
              .then(() => {
                this.$router.push({
                  name: 'student',
                  query: {id: data.student.id}
                })
              })
          })
          .catch(() => {
            if (!navigator.onLine) {
              this.$root.$emit('snackbar', {
                text: 'No momento você está offline. Tente novamente após retomar a conexão!',
                color: 'red',
              })
            }
          })
          .then(() => this.saving = false);
      },
      gotoStudent() {
        this.$router.push({
          name: 'student',
          query: {
            id: this.attendance.student.id
          }
        })
      },
    },
  }
</script>
