<template>
  <v-dialog persistent max-width="250" :value="value">
    <v-card>
      <v-card-text class="pt-6">
        <span class="subtitle-1">
          <slot/>
        </span>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="onCancel">
          {{ cancel }}
        </v-btn>
        <v-spacer/>
        <v-btn text color="primary" @click="onConfirm">
          {{ confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      cancel: {
        default: 'Não',
      },
      confirm: {
        default: 'Sim',
      },
    },
    methods: {
      onCancel() {
        this.$emit('cancel')
        this.$emit('input', false)
      },
      onConfirm() {
        this.$emit('confirm')
        this.$emit('input', false)
      },
    },
  }
</script>