<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="program.open">
    <v-card tile>
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="program.open = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">
                {{ program.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ program.faseName }}, {{ program.helpName }} {{ showSeconds(program.helpName) ? `, ${program.delay}"` : '' }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list class="pa-0">
          <template v-for="(application, index) in program.applications">
            <v-list-item :key="index" :class="{'yellow lighten-5': application.type === '2'}">
              <v-list-item-content>
                <v-list-item-title>
                  {{ application.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="flex-row">
                <template v-if="includeStatus('1')">
                  <v-btn icon class="mx-1" :color="isStatus(application.status, 1, 'red')" @click="onSelectStatus(application, 1)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <template v-if="includeStatus('2')">
                  <v-btn icon class="mx-1" :color="isStatus(application.status, 2, 'green')" @click="onSelectStatus(application, 2)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <template v-if="includeStatus('3')">
                  <v-btn icon class="mx-1" :color="isStatus(application.status, 3, 'blue')" @click="onSelectStatus(application, 3)">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </template>
                <template v-if="includeStatus('4')">
                  <v-btn icon class="mx-1" :color="isStatus(application.status, 4, 'orange')" @click="onSelectStatus(application, 4)">
                    <v-icon-error/>
                  </v-btn>
                </template>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions class="tertiary">
        <v-textarea auto-grow dense outlined hide-details background-color="white" row-height="17" rows="1" placeholder="Observações" v-model="program.comments"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      program: Object,
    },
    methods: {
      onSelectStatus(application, value) {
        application.status = application.status === value ? null : value
        this.$emit('change', this.program);
      },
      isStatus(status, is, color) {
        return status === is ? color : 'grey'
      },
      includeStatus(status) {
        return this.program.status.includes(status)
      },
      showSeconds(help) {
        return !['Ajuda necessária', 'Independente'].some(item => item.trim() === String(help).trim())
      },
    },
  }
</script>