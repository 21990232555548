export default {
  id: null,
  name: null,
  attempts: null,
  comments: null,
  fase: null,
  faseName: null,
  help: null,
  helpName: null,
  delay: 0,
  finished: false,
  applications: [],
}